<template>
<div class="footer">
    <div>
        <router-link to="/today" :class="{ active: c1 }">
            <div class="icon">
                <svg t="1620300435359" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1891" width="2rem" height="2rem">
                    <path d="M256 109.714286h512a73.142857 73.142857 0 0 1 73.142857 73.142857v658.285714a73.142857 73.142857 0 0 1-73.142857 73.142857H256a73.142857 73.142857 0 0 1-73.142857-73.142857V182.857143a73.142857 73.142857 0 0 1 73.142857-73.142857z m36.571429 73.142857a36.571429 36.571429 0 0 0-36.571429 36.571428v585.142858a36.571429 36.571429 0 0 0 36.571429 36.571428h438.857142a36.571429 36.571429 0 0 0 36.571429-36.571428V219.428571a36.571429 36.571429 0 0 0-36.571429-36.571428H292.571429z" p-id="1892" :fill="cc1"></path>
                    <path d="M292.571429 365.714286m36.571428 0l365.714286 0q36.571429 0 36.571428 36.571428l0 365.714286q0 36.571429-36.571428 36.571429l-365.714286 0q-36.571429 0-36.571428-36.571429l0-365.714286q0-36.571429 36.571428-36.571428Z" p-id="1893" :fill="cc1"></path>
                    <path d="M329.142857 219.428571h146.285714a36.571429 36.571429 0 1 1 0 73.142858H329.142857a36.571429 36.571429 0 1 1 0-73.142858z" p-id="1894" :fill="cc1"></path>
                </svg>
            </div>
            <div>Today</div>
        </router-link>
    </div>

    <div>
        <router-link to="/summary" :class="{ active: c2 }">
            <div class="icon">
                <svg t="1646380583093" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="16574" width="2rem" height="2rem">
                    <path d="M438.070857 763.794286a329.508571 329.508571 0 0 0 191.579429-61.732572l203.136 203.154286a48.603429 48.603429 0 0 0 34.706285 14.134857c27.867429 0 48-21.430857 48-48.859428 0-12.854857-4.278857-24.850286-13.714285-34.267429L699.922286 633.910857c42.422857-55.716571 67.712-124.708571 67.712-199.716571 0-181.284571-148.278857-329.563429-329.563429-329.563429-181.723429 0-329.563429 148.278857-329.563428 329.563429S256.347429 763.794286 438.070857 763.794286z m0-71.149715c-141.860571 0-258.432-116.992-258.432-258.432 0-141.421714 116.571429-258.432 258.432-258.432 141.421714 0 258.432 117.010286 258.432 258.432 0 141.44-117.010286 258.432-258.432 258.432z" p-id="16575" :fill="cc2"></path>
                </svg>
            </div>
            <div>搜索</div>
        </router-link>
    </div>

    <div>
        <router-link to="/efficiency" :class="{ active: c3 }">
            <div class="icon">
                <svg t="1621234058746" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8775" width="2rem" height="2rem">
                    <path d="M869.6 275.2L570.8 139c-32.4-14.8-85.4-14.8-117.8 0L154.4 275.2c-35.2 16-35.2 42.2 0 58.2l296 135c33.8 15.4 89.4 15.4 123.2 0l296-135c35.2-16 35.2-42.2 0-58.2zM450.4 750.4l-199.6-91c-8.4-3.8-18.2-3.8-26.6 0l-69.8 31.8c-35.2 16-35.2 42.2 0 58.2l296 135c33.8 15.4 89.4 15.4 123.2 0l296-135c35.2-16 35.2-42.2 0-58.2l-69.8-31.8c-8.4-3.8-18.2-3.8-26.6 0l-199.6 91c-33.8 15.4-89.4 15.4-123.2 0z" p-id="8776" :fill="cc3"></path>
                    <path d="M869.6 483.2l-63.4-28.8c-8.4-3.8-18-3.8-26.4 0l-216 97.8c-30.6 10.4-73.2 10.4-103.8 0l-216-97.8c-8.4-3.8-18-3.8-26.4 0l-63.4 28.8c-35.2 16-35.2 42.2 0 58.2l296 135c33.8 15.4 89.4 15.4 123.2 0l296-135c35.4-16 35.4-42.2 0.2-58.2z" p-id="8777" :fill="cc3"></path>
                </svg>
            </div>
            <div>App</div>
        </router-link>
    </div>
    <div>
        <router-link to="/about" :class="{ active: c4 }">
            <div class="icon">
                <svg t="1621235137292" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9355" width="2rem" height="2rem">
                    <path d="M541.8 701.2c-1.4-16.4-15.2-29.2-31.8-29.2-15.2 0-28 10.8-31.2 25L447.6 854c-1 4.6-1.6 9.2-1.6 14 0 35.4 28.6 60 64 60s64-24.6 64-60c0-5.8-0.8-11.4-2.2-16.8l-30-150z m70 8.8z m166 62.6c-3.4-2.8-6.6-5.8-10.2-7.8l-119.2-87c-11.6-7.6-24-7.4-33 1.8-8.2 8.2-9.4 20.8-3.4 30.8l90 123.6c1.8 3 4.4 5.6 6.8 8.4 15.6 18.2 50.2 19.2 69.2 0 18.8-19.6 18.6-54.4-0.2-69.8zM745 670z m125.8-30h-119c-7.8 0-14.8 4.4-16.2 12.4-1.2 7.2 2.6 14.2 9.4 17.6h0.2l115.4 35.6c16.6 3.8 35.4-11 35.4-29.6 0-23.6-9.6-36-25.2-36z m-592.4 30z m0 0c6.6-3.2 10.6-10.4 9.4-17.6-1.4-8-7.8-12.4-16.2-12.4H152.6c-15.2 0-24.6 16.6-24.6 35.2s18.2 34.2 34.8 30.4l115.4-35.6h0.2z m97.4 7.6l-119.2 86.8c-3.6 2.2-7.2 4.8-10.2 7.8-19.2 19.2-19.2 50.2 0 69.2 19.2 19.2 50.2 19.2 69.2 0 2.6-2.6 4.8-5.4 6.8-8.4l90-123.2c6-10 5-22.4-3.4-30.6-9.2-9-23.6-9.6-33.2-1.6z m341.6-400.2c0-115.2-144.8-181.4-192-181.4 24 161.6-203.4 226-224 362.6-20.6 136.6 96 181.4 96 181.4 32.6-113.2 144.8-151.2 219.4-199.6 81.4-52.8 100.6-102.6 100.6-163z m0 241.8c10-29.4 3.8-98.6-6-126.8-18 35-52.6 64.8-94.4 92-17.2 11.2-41.8 21.6-59.6 31.8-37.4 21-68 41.2-92.6 68.8 22.6 32.8 62.6 55.4 92.6 55.4 72-0.4 128-27.4 160-121.2z" p-id="9356" :fill="cc4"></path>
                    <path d="M323.6 343.8c23.4-30.2 49.8-47.6 76.4-72.4 26.8-25 44.2-42.4 55-62.6-21.4-40.2-61.6-56.2-89.4-52.2 4.6 57.2-60 88-64 129.6-2.6 25.2 10.8 50 22 57.6z" p-id="9357" :fill="cc4"></path>
                </svg>
            </div>
            <div>关于</div>
        </router-link>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            c1: false,
            c2: false,
            c3: false,
            c4: false,
            cc1: "#959596",
            cc2: "#959596",
            cc3: "#959596",
            cc4: "#959596",
        };
    },
    methods: {
        classchange() {
            if (this.$route.path == "/today") {
                this.c1 = true;
                this.c2 = false;
                this.c3 = false;
                this.c4 = false;
                this.cc1 = "#0071E3";
                this.cc2 = "#959596";
                this.cc3 = "#959596";
                this.cc4 = "#959596";
            } else if (this.$route.path == "/summary") {
                this.c2 = true;
                this.c1 = false;
                this.c3 = false;
                this.c4 = false;
                this.cc1 = "#959596";
                this.cc2 = "#0071E3";
                this.cc3 = "#959596";
                this.cc4 = "#959596";
            } else if (this.$route.path == "/efficiency") {
                this.c3 = true;
                this.c1 = false;
                this.c2 = false;
                this.c4 = false;
                this.cc1 = "#959596";
                this.cc2 = "#959596";
                this.cc3 = "#0071E3";
                this.cc4 = "#959596";
            } else if (this.$route.path == "/about") {
                this.c4 = true;
                this.c1 = false;
                this.c2 = false;
                this.c3 = false;
                this.cc1 = "#959596";
                this.cc2 = "#959596";
                this.cc3 = "#959596";
                this.cc4 = "#0071E3";
            }
        },
    },
    created() {
        this.classchange();
    },
    watch: {
        $route() {
            this.classchange();
        },
    },
};
</script>

<style scoped>
.footer {
    height: 8vh;
    width: 100vw;
    background-color: #f7f7f7;
    display: -webkit-flex;
    /* Safari */
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    font-size: 0.5rem;
    z-index: 5;
    overflow: hidden;
    /* border-top: 1px solid rgba(0, 0, 0, 0.3); */
    /* transform: scaleY(0.5); */
}

a {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-decoration: none;
    color: #959596;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.active {
    color: #0071e3;
}
</style>
