<template>
<div class="main">
    <div class="body">
        <keep-alive>
            <router-view />
        </keep-alive>
    </div>
    <!-- @play="mplay()" @pause="mpause()" @switch="mswitch()" @sliderChange="slider()" -->
    <div class="footer">
        <MFooter></MFooter>
    </div>
</div>
</template>

<script>
import MFooter from "@/common/m-footer";
export default {
    components: {
        MFooter,
    },
    data() {
        return {
            ll: false,
            timer: null,
        }
    },
    // methods: {
        // currentTime() {
        //     this.$store.commit('cTime', this.$refs.audio.currentTime)
        //     this.$store.commit('timeSlider', this.$refs.audio.currentTime / this.$refs.audio.duration * 100)
        //     //  console.log(this.$store.state.musicState.timeSlider)
        //     // console.log("ct")
        //     const that = this
        //     this.timer = setTimeout(function () {
        //         that.currentTime()
        //         if (that.$refs.audio.currentTime == that.$refs.audio.duration) {

        //         }
        //     }, 1000)
        // },
        // play() {
        //     // this.$refs.audio.pause()
        //     clearInterval(this.timer)
        //     //异步播放
        //     let times = 3
        //     const that = this
        //     let t = setInterval(function () {
        //         if (times === 0) {
        //             clearInterval(t);
        //         }
        //         that.$refs.audio.play()
        //         that.$store.commit('tTime', that.$refs.audio.duration)
        //         times--
        //     }, 100);
        //     this.currentTime()
        // },
        // pause() {
        //     clearInterval(this.timer);
        //     this.timer = null;
        //     //防止暂停太快没有成功
        //     let times = 3
        //     const that = this
        //     let t = setInterval(function () {
        //         if (times === 0) {
        //             clearInterval(t);
        //         }
        //         that.$refs.audio.pause()
        //         times--
        //     }, 100);
        //     this.$refs.audio.pause()
        // },
        // sw() {
        //     clearInterval(this.timer)
        //     this.pause()
        //     this.$refs.audio.load()
        //     this.play()
        //     // console.log("1")
        // },
        // slider() {
        //     let time = this.$store.state.titleTime * this.$store.state.timeSlider * 0.01;
        //     time = Math.round(time * Math.pow(10, 0)) / Math.pow(10, 0)
        //     if ('fastSeek' in this.$refs.audio) {
        //         // 改变audio.currentTime的值
        //         this.$refs.audio.fastSeek(time)
        //     } else {
        //         console.log(time)
        //         this.$refs.audio.currentTime = this.$store.state.currentTime

        //     }

        // }
    // },
};
</script>

<style scoped>
.main {
    /* position: relative; */
}

.body {
    height: 92vh;
    overflow-y: scroll;
}

.footer {
    position: fixed;
    bottom: 0;
}
</style>
